import React from "react";
import { graphql } from "gatsby";
import Collaboration from "../../templates/CollaborationTemplate";

export default function CollaborationPage({ data: { page } }) {
    
	return <Collaboration page={page} locale="en" />
}


export const query = graphql`
fragment YhteistyoImage on File {
    childImageSharp {
        fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
        }
    }
}
query {
    page: wpPage(slug: { eq: "collaboration" }) {
        content
        title
        seo {
            metaDesc
            title
        }
        locale {
            locale
        }
        yhteistyoData {
            hankkeetCta
            hankkeetRepeater {
                hankeText
            }
            yhteistyoParagraph
            yhteistyotRepeater {
                yhteistyoText
                yhteistyoLogo {
                    localFile {
                        childImageSharp { 
                            gatsbyImageData(width: 400)
                        }
                    }
                }
            }
        }
        frontpageLinkTitle {
            styledTitle
        }
    }
}
`;